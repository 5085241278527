'use strict'

$id = $('body').attr('id')
$class = $('body').attr('class')

$('a[href^="#"]'+'a[href!="#"]').on('click.smoothScroll'
  (e)->
    e.preventDefault()

    smoothScroll($(this).attr('href'))
)

resizeFlg = {}
resizeFlg.pc = true
resizeFlg.sp = true
firstView = ''

# 固定コンテンツ制御
fixedContents =
  (offFlg)->
    mainHeight = $('#main').height()
    subHeight = $('#sub').height()

    if subHeight < mainHeight
      $('#sub').css('min-height', $('#main').outerHeight(true))

      mainContentsHeight = $('#main').outerHeight(true)
      subContentsHeight = $('#sub').outerHeight(true)
      windowHeight = $(window).height()
      # 30 = メニュー下マージン
      adjustMargin = $('.h-navi').outerHeight() + 10
      # adjustMargin = $('#gnavi').outerHeight() + 0

      $fixContents = $('.js-fix-contents')
      fixContentsHeight = $('.js-fix-contents').outerHeight(true)
      fixContentsPos = $fixContents.offset().top - adjustMargin

      fixFlg = false
      bottomFlg = false

      initial = ()->
        # メインコンテンツの高さが変わった時時再度高さ適応
        currentSubHeight = $('#sub').outerHeight(true)
        currentMainContentsHeight = $('#main').outerHeight(true)

        # if currentMainContentsHeight > mainContentsHeight
          # $('#sub').removeAttr('style')
        $('#sub').css('min-height', currentMainContentsHeight)

        currentSubContentsHeight = $('#sub').outerHeight(true)

        # メニュー開閉等で位置が変わった時再計算
        currentFixContentsPos = $('.js-fix-contents').offset().top - adjustMargin

        if $('.js-fix-contents').not('.is-fixed,.is-bottom').length isnt 0
          #currentFixContentsPos = $('.js-fix-contents').not('.is-fixed,.is-bottom').offset().top - adjustMargin

          if currentFixContentsPos > fixContentsPos
            fixContentsPos = currentFixContentsPos

        # サブコンテンツの方が高さが低い時のみ実行
        if currentSubContentsHeight <= currentMainContentsHeight
          scrollPos = $(window).scrollTop()
          fixContentsBottomPos = $('#container').height() - (scrollPos + windowHeight) - $('#footer').outerHeight() - ((adjustMargin + fixContentsHeight) - windowHeight + 70)

          if scrollPos > fixContentsPos
            if not fixFlg and not bottomFlg
              $fixContents.addClass('is-fixed')
              fixFlg = true
          else
            $fixContents.removeClass('is-fixed')
            if fixFlg
              fixFlg = false

          if fixContentsBottomPos < 0
            $fixContents.removeClass('is-fixed')
            if fixFlg
              fixFlg = false

              if not bottomFlg
                $fixContents.addClass('is-bottom')
                bottomFlg = true
          else
            if bottomFlg
              $fixContents.removeClass('is-bottom').addClass('is-fixed')
              bottomFlg = false

      initial()

      $(window).on('scroll.fixedContents resize.fixedContents',
        ()->
          if offFlg is true
            $(window).off('scroll.fixedContents resize.fixedContents')
            $('#main').removeAttr('style')
            $('#sub').removeAttr('style')
            $('.js-fix-contents').off()
            $('.js-fix-contents').removeClass('is-fixed')
            $('.js-fix-contents').removeClass('is-bottom')
          else
            initial()
      )

$(window).on('scroll', ->
  if $(window).scrollTop() >= 100
    if $(window).scrollTop() >= 1000
      $('.h-group-link').addClass('is-active')
      $('.h-group-link').removeClass('is-none')
    else
      $('.h-group-link').addClass('is-none')
  else
    $('.h-group-link').removeClass('is-active')
    $('.h-group-link').removeClass('is-none')

  ()->
    $('#gnavi').removeClass('g-navi-relative')
    return
)

$(window).on('load',
  ()->
    hash = location.hash
    if $(hash).length
      $('#gnavi').addClass('g-navi-relative')

    if window.matchMedia('(max-width:640px)').matches
      # SPの処理
      currentScrollPos = 0
      firstView = 'SP'

      $('.menu.button.sp').on('click.sp',
        (e)->
          e.stopPropagation()
          if $('body').hasClass('is-fix')
            $('.close.button.sp').triggerHandler('click.sp')
          else
            currentScrollPos = $(window).scrollTop()

            $('body').addClass('is-fix').css({
              top: currentScrollPos * -1
            })

            $(this).closest('.h-menu-wrapper').addClass('is-open')

      )

      $('.close.button.sp').on('click.sp',
        (e)->
          e.stopPropagation()
          $('body').removeClass('is-fix').removeAttr('style')
          $('html, body').scrollTop(currentScrollPos)

          $(this).closest('.h-menu-wrapper').removeClass('is-open')
      )

      $('.l-container > *:not(".l-header")').on('click.sp',
        (e)->
          e.stopPropagation()
          if $('body').hasClass('is-fix')
            $('.close.button.sp').triggerHandler('click.sp')
      )

    else
      firstView = 'PC'
      $('.l-header .size-item').on('click',
        ()->
          $('.l-header .size-item').removeClass('is-active')
          $(this).addClass('is-active')
      )

      $('#gnavi').headroom({
        offset: $('#header').outerHeight(true) - $('#gnavi').outerHeight(true)
      })

      if $('.js-fix-contents').length isnt -1
        fixedContents()

    if location.href.indexOf('#') isnt -1
      $('a[href="#'+location.href.split('#')[1]+'"]').triggerHandler('click.smoothScroll')
)

$(window).on('resize',
  ()->
    if window.matchMedia('(max-width:640px)').matches
      if firstView is 'PC' and resizeFlg.sp is true or
      firstView is false and resizeFlg.sp is true
        fixedContents(true)

        currentScrollPos = 0

        $('.menu.button.sp').on('click.sp',
          ()->
            if $('body').hasClass('is-fix')
              $('.close.button.sp').trigger('click.sp')
            else
              currentScrollPos = $(window).scrollTop()

              $('body').addClass('is-fix').css({
                top: currentScrollPos * -1
              })

              $(this).closest('.h-menu-wrapper').addClass('is-open')
        )

        $('.close.button.sp').on('click.sp',
          ()->
            $('body').removeClass('is-fix').removeAttr('style')
            $('html, body').scrollTop(currentScrollPos)

            $(this).closest('.h-menu-wrapper').removeClass('is-open')
        )

        resizeFlg.pc = true
        resizeFlg.sp = false
        firstView = false
    else
      if firstView is 'SP' and resizeFlg.pc is true or
      firstView is false and resizeFlg.pc is true
        $('.menu.button.sp').off('click.sp')
        $('.close.button.sp').off('click.sp')

        $('.l-header .size-item').on('click',
          ()->
            $('.l-header .size-item').removeClass('is-active')
            $(this).addClass('is-active')
            fixedContents()
        )

        $('#gnavi').headroom({
          offset: $('#header').outerHeight(true) - $('#gnavi').outerHeight(true)
        })

        if $('.js-fix-contents').length isnt -1
          fixedContents()

        resizeFlg.pc = false
        resizeFlg.sp = true
        firstView = false
)

$(()->
  $('.modal-disp').on('click',
    (e)->
      e.preventDefault()
      $('.modal').addClass('is-modal')
      $('body').addClass('is-modal')
  )

  $('.modal__item a').on('click',
    (e)->
      e.stopPropagation()
  )

  $('.modal').on('click',
    (e)->
      e.preventDefault()
      $('.modal').removeClass('is-modal')
      $('body').removeClass('is-modal')
  )
)
